.gallery-page {
  width: 100vw;
  min-height: calc(var(--app-height) - 60px);
}

.gallery-page .button {
  margin: 2em;
}

.gallery-page .image-grid {
  /* padding: 0 2em; */
  text-align: center;
  /* height: 100%; */
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  position: relative;
  border: 4px solid var(--white);
}

/* .gallery-page .image-grid:after {
  content: '';
  width: 4px;
  height: 100%;
  background: var(--white);
  position: absolute;
  left: calc(50% - 2px);
} */

.gallery-page .image-grid .image-wrapper {
  width: 50%;
  margin: 0;
  box-sizing: border-box;
}

.gallery-page .image-grid img {
  border: 4px solid var(--white);
  box-sizing: border-box;
  border-radius: 8px;
}

.gallery-page .image-grid .image-wrapper:not(:first-child) {
  /* margin-top: 7em; */
}

.gallery-page .image-grid .image-wrapper:last-child {
  /* margin-bottom: 50vh; */
}

.gallery-page .image-grid .preview {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  /* border: 4px solid var(--white); */
  width: 100%;
  height: auto;
  /* border-radius: 4px; */
}

.gallery-page .image-grid .preview.is-loaded {
  opacity: 1;
}

.gallery-page .nope-button {
  float: left;
}

.gallery-page .download-button {
  float: right;
}

.gallery-page p {
  margin: 2em auto;
}

.gallery-page .empty-message {
  margin: auto;
}
